/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Card, Col, Row, Button, Table, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import DataGridForm from '../../../../components/common/DataGridForm';
import { loadingAction } from '../../../../redux/actions';
import { catchError, Fetch, FetchApi, FetchApiElastic } from '../../../../utils/fetch';
import { formatDate } from '../../../../utils/formatDate';
import { Formik, Field } from "formik";
import MonthPickerInput from 'react-month-picker-input'
import TiposFeatures from "../../../../data/tipo-features.json";
import { AlertInfo, AlertError } from '../../../../components/alert';
import "./logs-features.scss";


function LogsFeatures({ isLoadingAction, isLoading }) {

    const [mes, setMes] = React.useState((new Date().getMonth() + 1).toString().padStart(2, "00"))
    const [anio, setAnio] = React.useState(new Date().getFullYear())

    const [checkButton, setCheckButton] = React.useState(false)

    const [state, setState] = React.useState({
        type: '', 
        feature: '',
        monthYear: `01/${mes}/${anio}`    
    })

    const [users, setUsers] = React.useState("");
    const [amount, setAmount] = React.useState("");
    const [period, setPeriod] = React.useState("");
    const [headerMsg, setMsgHeader] = React.useState("");

    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [dateRange, setDateRange] = useState({
    startDate: '',
    endDate: ''
    });
    
    // Utilizamos useEffect para establecer el rango inicial
    useEffect(() => {
        const initialDateRange = generateDateRange(selectedYear, selectedMonth);
        setDateRange(initialDateRange);
    }, []); // Se ejecuta solo al montar el componente

    const FormHandler = (event) =>{

        event.preventDefault();
    
    }

    const formatDate = (dateString) => {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Mes comienza desde 0
      const day = String(date.getDate()).padStart(2, "0");
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds()).padStart(2, "0");
      return `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
    };
    
    // Función para obtener el último día del mes
    const getLastDayOfMonth = (year, month) => {
      // El mes en new Date() va de 0-11, y al poner día 0 nos da el último día del mes anterior
      return new Date(year, month, 0).getDate();
    };
  
    // Función para generar el rango de fechas
    const generateDateRange = (year, month) => {
      const lastDay = getLastDayOfMonth(year, month);
      return {
        startDate: formatDate(`${year}/${month}/01`),
        endDate: formatDate(`${year}/${month}/${lastDay}`)
      };
    };

    const handleSubmit = async () =>{

        if(!state?.feature){
            setMsgHeader("Por favor selecciona una funcionalidad.")
            return;
        }

        if(state?.monthYear){
            let partes = state.monthYear.split("/"); 
            let mes = partes[1];
            let anio = partes[2].slice(-2);
            let type = checkButton ? "WEB" : "APP";
            isLoadingAction(true)

            try {
                // let res  = await FetchApi(`Api/v1/Account/GetLogsCMS?type=${type}&feature=${state?.feature}&month=${mes}&year=${anio}`, 'get', null)
                FetchApiElastic(
                    `api/LogFeature/GetLogsFeature`,
                    "post",
                    {
                        source: "hb-online-log-feature-cms",
                        month: mes,
                        year: anio,
                        startDate: dateRange.startDate,
                        endDate: dateRange.endDate,
                        type: type,
                        feature: state.feature,                          
                    }
                    )
                    .then((res) => {
                        setUsers(res.data?.users)
                        setAmount(res.data?.amount)
                        setPeriod(res.data?.mes)
                    })
                    .finally(() => {
                        isLoadingAction(false);
                    });

                isLoadingAction(false)
            } catch (error) {
                isLoadingAction(false)
                AlertError('Ocurrió un error procesando la solicitud.<br/>')
                catchError(error);
            }

        }else{
            AlertError('Ocurrió un error procesando la solicitud.<br/>')

        }

        isLoadingAction(false)

    }

    const handleCheckboxChange = (event) => {
        setCheckButton(event.target.checked)
    };

    const handleFeatureChange = (event) => {
        setMsgHeader("");
        setState({ ...state, feature: event.target.value })
    };

    React.useEffect(() => {

    }, [])

    return (
        <React.Fragment>
            <Row>
                <Col md="12">
                    <Card>
                        <Card.Header>
                            Log de Funcionalidades
                        </Card.Header>
                        <Card.Body>
                            <Row className=''>
                                <Col md="2" >
                                    <div className="d-flex justify-content-center align-items-center">

                                        <p className='paddingSwitch' style={{marginRight: "15px"}}>APP</p>

                                        <Form onSubmit={(event) => this.FormHandler()}>
                                            <Form.Check // prettier-ignore
                                                type="switch"
                                                id="custom-switch"
                                                className="custom-switch"
                                                checked={checkButton}
                                                onChange={handleCheckboxChange}
                                            />
                                        </Form>

                                        <p className='paddingSwitch'>WEB</p>

                                    </div>

                                </Col>

                                <Col md="2">
                                    <select class="form-control" name="idType" onChange={handleFeatureChange} value={state?.feature}>
                                        <option selected="selected" value="">Funcionalidad</option>
                                        {
                                            TiposFeatures.map((item, index) => {
                                                return <option value={item.value}>{item.text}</option>
                                            })
                                        }
                                    </select>
                                </Col>

                                <Col md="2">
                                    <MonthPickerInput
                                        maxDate={new Date()}
                                        lang="es"
                                        year={new Date().getFullYear()}
                                        month={new Date().getMonth()}
                                        onChange={function (maskedValue, selectedYear, selectedMonth) {

                                            const newMonth = selectedMonth + 1;

                                            // Actualizamos los estados del mes y año
                                            setSelectedMonth(newMonth);
                                            setSelectedYear(selectedYear);

                                            // Calculamos el primer y último día del mes
                                            const lastDay = getLastDayOfMonth(selectedYear, newMonth);

                                            // Creamos el rango de fechas
                                            const newDateRange = {
                                            startDate: formatDate(`${selectedYear}/${newMonth}/01`),
                                            endDate: formatDate(`${selectedYear}/${newMonth}/${lastDay}`),
                                            };

                                            // Actualizamos el estado del rango
                                            setDateRange(newDateRange);
                                            setState({ ...state, monthYear: '01/' + (selectedMonth + 1).toString().padStart(2, "00") + '/' + selectedYear })
                                        }}
                                        
                                    />
                                </Col>


                                <Col md="2">
                                    <label>&nbsp;</label>
                                    <Button onClick={handleSubmit}><i className="fa fa-search mr-2"></i>Buscar</Button>
                                </Col>
                            </Row>

                            

                        </Card.Body>
                    </Card>

                    {/* CARD DE ULTIMO PERIODO */}
                    <Card>
                        <Card.Header>
                            {headerMsg}
                        </Card.Header>
                        <Card.Body>

                            <Row className="">
                                <Col md="8">
                                <Table striped bordered hover>
                                    <thead>
                                    <tr>
                                        <th className="text-center">Periodo</th>
                                        <th className="text-center">Cantidad de usuarios</th>
                                        <th className="text-center">Cantidad de usos</th>
                                        <th className="text-center">Promedio diario</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td className="text-center">
                                        {period}
                                        </td>
                                        <td className="text-center">
                                        {users}
                                        </td>
                                        <td className="text-center">
                                        {amount}
                                        </td>
                                        <td className="text-center">
                                        {amount ? Math.trunc( amount / 30 ): ""}
                                        </td>
                                    </tr>
                                    </tbody>
                                </Table>
                                </Col>
                            </Row>


                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => {

    return {
        isLoading: state.loading,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        isLoadingAction: (data) => { dispatch(loadingAction(data)) }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(LogsFeatures);